import React from "react"
import ReactDOM from "react-dom/client"
// import App from "./App"
import "./index.css"
import UnderConstruction from "./pages/UnderConstruction"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <link rel="stylesheet" href="https://rsms.me/inter/inter.css"></link>
    <UnderConstruction />
  </React.StrictMode>,
)
